import GlobalWrapper from '@gtg/components/globalWrapper'
import { color, fontSize } from '@gtg/styles/theme'
import media from '@gtg/utils/media-queries'
import React from 'react'
import { css } from 'styled-components'
import { Script } from 'gatsby'

const customGlobalStyle = css`
  h1 {
    font-family: serif;
    font-weight: 500;
    font-size: ${fontSize.f11};
    ${media.xl`
      font-size: ${fontSize.f10};
    `}
    ${media.lg`
      font-size: ${fontSize.f9};
    `}
    ${media.md`
      font-size: ${fontSize.f7};
      margin-bottom: 1.25rem;
    `}
    ${media.sm`
      margin-bottom: 1rem;
    `}
    ${media.xs`
      font-size: ${fontSize.f6};
      margin-bottom: 0.75rem;
    `}
  }

  h2 {
    font-size: ${fontSize.f4};
    letter-spacing: 0.3px;
  }

  button {
    text-transform: none;
  }
`

const CustomGlobalWrapper = props => {
  return (
    <>
      <GlobalWrapper
        customGlobalStyle={customGlobalStyle}
        selectionColor={color.lightBlue500}
        themeColor={color.lightBlue500}
        hoverColor={color.lightBlue400}
        roundness={3}
      >
        {props.children}
      </GlobalWrapper>
    </>
  )
}

export default CustomGlobalWrapper
